import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  Divider,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
  Text,
  useColorModeValue,
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverBody,
  useDisclosure,
  useOutsideClick,
  PopoverArrow,
  Spinner,
  useMediaQuery,
} from '@chakra-ui/react'
import * as React from 'react'
import { FiMic, FiVolume, FiVolume2 } from 'react-icons/fi'
import { WarningTwoIcon } from '@chakra-ui/icons'
import { useAppSelector, useAppDispatch } from '../../utils/reduxUtils'
import { Link as ReactLink, useLocation, useNavigate } from 'react-router-dom'
import { logout } from '../../features/authorization/actions'
import { DevicesController } from '../DevicesController/devicesController'
import { AvatarWithPreload } from '../AvatarWithPreload/avatarWithPreload'
import { mobileBreakPoint } from '../../utils/mobileBreakPoint'

export const Navbar = () => {
  const {
    isOpen: isOpenDeviceController,
    onOpen: onOpenDeviceController,
    onClose: onCloseDeviceController,
  } = useDisclosure()
  const navigate = useNavigate()

  const {
    first_name,
    isSSOAuthenticated,
    features,
    is_supervisor,
    is_annotator,
    logout_url,
  } = useAppSelector((state) => state.user)

  const { mediaState, areAudioPermissions, deviceTest } = useAppSelector(
    (state) => state.audio,
  )
  const dispatch = useAppDispatch()
  const {
    onClose: onCloseTooltip,
    isOpen: isOpenTooltip,
    onToggle: onToggleTooltip,
  } = useDisclosure()

  const ref: any = React.useRef()
  useOutsideClick({
    ref: ref,
    handler: () => onCloseTooltip(),
  })

  const handleLogout = () => {
    dispatch(logout({ url: logout_url }))
  }

  const { pathname } = useLocation()
  const [isMobile] = useMediaQuery(mobileBreakPoint)

  return (
    <Box as="section">
      <Box
        id="navbar"
        as="nav"
        bg="bg-surface"
        boxShadow={useColorModeValue('sm', 'sm-dark')}
      >
        <Container p="4" maxW="full">
          <Flex justify="space-between">
            <HStack spacing="4">
              <Image
                src="/logo.svg"
                onClick={() => navigate('/')}
                w="2.5em"
                data-qa__id="navbar_logo"
              />
              {!isMobile && (
                <ButtonGroup variant="ghost" spacing="1">
                  <Button
                    as={ReactLink}
                    aria-current={
                      pathname.includes('dashboard') ? 'page' : undefined
                    }
                    to={`/dashboard${
                      location.pathname.includes('/studio') ||
                      location.pathname.includes('/dashboard')
                        ? location.search
                        : ''
                    }`}
                    data-qa__id="navbar_dashboard"
                  >
                    Dashboard
                  </Button>
                  <Button
                    as={ReactLink}
                    aria-current={
                      pathname === '/' || pathname.includes('studio')
                        ? 'page'
                        : undefined
                    }
                    to={`/studio${
                      location.pathname.includes('/studio') ||
                      location.pathname.includes('/dashboard')
                        ? location.search
                        : ''
                    }`}
                    data-qa__id="navbar_studio"
                  >
                    Sessions
                  </Button>
                  {/* {is_supervisor && is_annotator && (
                    <Button
                      as={ReactLink}
                      aria-current={
                        pathname === '/' ||
                        pathname.includes('upload-recordings')
                          ? 'page'
                          : undefined
                      }
                      to={`/upload-recordings`}
                      data-qa__id="navbar_upload"
                    >
                      Upload
                    </Button>
                  )} */}
                  <Button
                    as={ReactLink}
                    to="/live"
                    aria-current={
                      location.pathname.includes('/live') ? 'page' : undefined
                    }
                    data-qa__id="navbar_live"
                  >
                    Live
                  </Button>
                </ButtonGroup>
              )}
            </HStack>
            <HStack spacing="2">
              {first_name && (
                <>
                  <Text
                    fontSize="sm"
                    color="muted"
                    data-qa__id="navbar_greeting"
                    pr="10px"
                  >
                    Hello, {first_name}
                  </Text>
                  {(location.pathname.includes('/live') || isMobile) && (
                    <Divider height="12px" orientation="vertical" />
                  )}
                </>
              )}
              {mediaState.isConnected && !deviceTest.isOpen && (
                <HStack textAlign="end">
                  <Text
                    w="40px"
                    fontSize="sm"
                    data-qa__id="navbar_latency"
                    textAlign="center"
                  >
                    {Math.floor(Number(mediaState.latency))}
                  </Text>
                  <Divider height="12px" orientation="vertical" />
                  {mediaState.isOutputAudioRegistered ? (
                    <IconButton
                      opacity="1"
                      cursor="default"
                      variant="ghost"
                      color="green"
                      _hover={{ bg: 'transparent' }}
                      aria-label="input"
                      icon={<FiVolume2 />}
                      data-qa__id="input_output_verified"
                    />
                  ) : (
                    <IconButton
                      opacity="0.8"
                      cursor="default"
                      variant="ghost"
                      _hover={{ bg: 'transparent' }}
                      aria-label="input"
                      icon={<FiVolume />}
                      data-qa__id="input_output"
                    />
                  )}
                  <Divider height="12px" orientation="vertical" />
                </HStack>
              )}
              {(location.pathname.includes('/live') || isMobile) && (
                <Popover
                  isOpen={isOpenDeviceController}
                  onClose={onCloseDeviceController}
                  onOpen={onOpenDeviceController}
                  placement="bottom-start"
                >
                  {!areAudioPermissions ||
                  mediaState.isConnected ||
                  mediaState.isConnecting ? (
                    <div>
                      {mediaState.isInputConnected === true &&
                      mediaState.isOutputConnected === true ? (
                        <IconButton
                          opacity="0.8"
                          cursor="not-allowed"
                          variant="ghost"
                          color={
                            mediaState.isInputAudioRegistered
                              ? 'green !important'
                              : ''
                          }
                          _hover={{ bg: 'transparent' }}
                          aria-label="input"
                          icon={<FiMic />}
                          data-qa__id="navbar_devices"
                        />
                      ) : (
                        <IconButton
                          opacity="1"
                          cursor="pointer"
                          variant="ghost"
                          color="red"
                          _hover={{ bg: 'transparent' }}
                          aria-label="input"
                          icon={<WarningTwoIcon />}
                          data-qa__id="navbar_devices"
                        />
                      )}
                    </div>
                  ) : (
                    <PopoverTrigger>
                      <div>
                        {mediaState.isInputConnected === undefined ||
                        mediaState.isOutputConnected === undefined ? (
                          <IconButton
                            opacity="1"
                            cursor="pointer"
                            variant="ghost"
                            _hover={{ bg: 'transparent' }}
                            aria-label="input"
                            icon={<Spinner size="sm" />}
                            data-qa__id="navbar_devices"
                          />
                        ) : mediaState.isInputConnected === true &&
                          mediaState.isOutputConnected === true ? (
                          <IconButton
                            opacity="1"
                            cursor="pointer"
                            variant="ghost"
                            color={
                              mediaState.isInputAudioRegistered
                                ? 'green !important'
                                : ''
                            }
                            _hover={{ bg: 'transparent' }}
                            aria-label="input"
                            icon={<FiMic />}
                            data-qa__id="navbar_devices"
                          />
                        ) : (
                          <IconButton
                            opacity="1"
                            cursor="pointer"
                            variant="ghost"
                            color="red"
                            _hover={{ bg: 'transparent' }}
                            aria-label="input"
                            icon={<WarningTwoIcon />}
                            data-qa__id="navbar_devices"
                          />
                        )}
                      </div>
                    </PopoverTrigger>
                  )}
                  <PopoverContent>
                    <>
                      <PopoverArrow />
                      <PopoverBody p="20px 15px">
                        <DevicesController
                          isOpenDeviceController={isOpenDeviceController}
                        />
                      </PopoverBody>
                    </>
                  </PopoverContent>
                </Popover>
              )}

              <Menu>
                <Divider height="12px" orientation="vertical" pr="10px" />
                <MenuButton data-qa__id="navbar_avatar">
                  <AvatarWithPreload
                    cursor="pointer"
                    src={''}
                    boxSize="10"
                    name={first_name}
                  />
                </MenuButton>
                <Portal>
                  <MenuList p="0">
                    <MenuItem
                      onClick={handleLogout}
                      data-qa__id="navbar_menu_logout"
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </Portal>
              </Menu>
            </HStack>
          </Flex>
        </Container>
      </Box>
    </Box>
  )
}
